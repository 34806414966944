
import {
  watch,
  ref,
  onMounted,
  onBeforeUnmount,
  defineComponent,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";
import type { EditorView } from "@codemirror/view";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";
import { useCodemirror } from "../composables/useCodemirror";

import { debounce } from "@/helpers";

export default defineComponent({
  name: "programmingInteraction",

  props: {
    ...makeResponseIdentifierProps(),
    dataLang: { type: String, required: true }
  },

  setup(props) {
    const editorEl = ref<HTMLDivElement | null>(null);
    const attrs = useAttrs();
    const store = useStore();

    const required = attrs["data-required"] === "true";
    const autocompletion = attrs["data-autocompletion"] === "true";

    let editor: EditorView | null = null;
    let firstSave = true;

    const { revision } = storeToRefs(useStore());
    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      interactionInitialized
    } = useResponseIdentifier(props);

    let text = "";
    if (storedValue.value) {
      const div = document.createElement("div");
      div.innerHTML = storedValue.value.html;
      text = div.innerText;
      div.remove();
    }
    const {
      createEditor,
      value,
      getSubmissionData,
      darkMode,
      setEditorContents
    } = useCodemirror(props.dataLang, autocompletion, text, () =>
      saveData(true)
    );

    function saveData(contentCheck: boolean) {
      debouncedCommit.clear();
      const s = getSubmissionData();
      if (
        (contentCheck || firstSave) &&
        s.html == (storedValue.value ? storedValue.value.html : "")
      ) {
        firstSave = false;
        return;
      }
      commitValue(s);
    }

    const debouncedCommit = debounce(saveData, 2000, 4000);

    watch(value, () => {
      debouncedCommit.call();
    });
    watch(revision, revision => {
      if (!revision || !revision.form) return;
      let text = "";
      if (revision.form[props.responseIdentifier]) {
        const div = document.createElement("div");
        div.innerHTML = revision.form[props.responseIdentifier].html;
        text = div.innerText;
        div.remove();
      }
      setEditorContents(text);
    });

    store.setCustomValidator({
      responseIdentifier: props.responseIdentifier,
      fn: () => {
        return storedValue.value && storedValue.value.html;
      }
    });
    registerResponseIdentifier();
    setResponseIdentifierRequired(required);

    onMounted(() => {
      if (!editorEl.value) throw "invalid component element";
      const offset = window.innerHeight - 150;
      editorEl.value.style.setProperty("--max-height", `${offset}px`);
      window.addEventListener("resize", () => {
        const offset = window.innerHeight - 150;
        editorEl.value!.style.setProperty("--max-height", `${offset}px`);
      });

      createEditor(editorEl.value).then(e => {
        editor = e;
        interactionInitialized();
      });

      store.addCleanupCallback(() => {
        saveData(true);
        return Promise.resolve();
      });
    });

    onBeforeUnmount(() => {
      if (editor) editor.destroy();
    });

    return {
      editorEl,
      darkMode
    };
  }
});
