import { watch, computed, ref } from "vue";
import { StreamLanguage, LanguageSupport } from "@codemirror/language";
import { configureNesting } from "@lezer/html";
import { useStore } from "@/store/index";
import {
  foldGutter,
  foldKeymap,
  indentOnInput,
  syntaxHighlighting,
  bracketMatching,
  defaultHighlightStyle
} from "@codemirror/language";

import { highlightTree } from "@lezer/highlight";
import {
  history,
  defaultKeymap,
  historyKeymap,
  indentWithTab
} from "@codemirror/commands";

import { materialDark } from "cm6-theme-material-dark";
import { highlightSelectionMatches } from "@codemirror/search";
import { searchKeymap } from "@codemirror/search";
import { EditorState, Compartment } from "@codemirror/state";
import {
  closeBrackets,
  autocompletion,
  closeBracketsKeymap,
  completionKeymap
} from "@codemirror/autocomplete";

import {
  EditorView,
  lineNumbers,
  highlightActiveLineGutter,
  highlightSpecialChars,
  drawSelection,
  dropCursor,
  rectangularSelection,
  crosshairCursor,
  highlightActiveLine,
  keymap
} from "@codemirror/view";
import { storeToRefs } from "pinia";

async function getLangExt(dataLang: string) {
  // new language support are separate packages
  switch (dataLang) {
    case "typescript":
      return import(
        /* webpackChunkName: "code_lang_js" */ "@codemirror/lang-javascript"
      ).then(l => l.javascript({ typescript: true }));
    case "javascript":
      return import(
        /* webpackChunkName: "code_lang_js" */ "@codemirror/lang-javascript"
      ).then(l => l.javascript({}));
    case "jsx":
      return import(
        /* webpackChunkName: "code_lang_js" */ "@codemirror/lang-javascript"
      ).then(l =>
        l.javascript({
          jsx: true
        })
      );
    case "tsx":
      return import(
        /* webpackChunkName: "code_lang_js" */ "@codemirror/lang-javascript"
      ).then(l =>
        l.javascript({
          typescript: true,
          jsx: true
        })
      );
    case "html":
      // I need direct access to all in order to override configuration
      // default configuration doesn't support lang=ts
      return Promise.all([
        import(
          /* webpackChunkName: "code_lang_html" */ "@codemirror/lang-html"
        ),
        import(
          /* webpackChunkName: "code_lang_js" */ "@codemirror/lang-javascript"
        ),
        import(/* webpackChunkName: "code_lang_css" */ "@codemirror/lang-css")
      ]).then(values => {
        const html = values[0];
        const js = values[1];
        const css = values[2];
        const typescript = js.javascript({
          typescript: true
        });
        let htmlLanguage = html.htmlLanguage;
        // there's no way to reset or append to existing wrapper
        // if I leave old one on top of mine it causes lag, style jumps etc
        (htmlLanguage.parser as any).wrappers = [];

        htmlLanguage = html.htmlLanguage.configure({
          wrap: configureNesting([
            {
              tag: "script",
              attrs(attrs) {
                return attrs.lang == "ts";
              },
              parser: typescript.language.parser
            },
            {
              tag: "script",
              attrs(attrs) {
                return (
                  attrs.lang != "ts" &&
                  (!attrs.type ||
                    /^(?:text|application)\/(?:x-)?(?:java|ecma)script$|^module$|^$/i.test(
                      attrs.type
                    ))
                );
              },
              parser: js.javascriptLanguage.parser
            },
            {
              tag: "style",
              attrs(attrs) {
                return (
                  (!attrs.lang || attrs.lang == "css") &&
                  (!attrs.type ||
                    /^(text\/)?(x-)?(stylesheet|css)$/i.test(attrs.type))
                );
              },
              parser: css.cssLanguage.parser
            }
          ])
        });
        return new LanguageSupport(htmlLanguage, [
          html.htmlLanguage.data.of({
            autocomplete: html.htmlCompletionSourceWith({})
          }),
          html.autoCloseTags,
          typescript.support,
          css.css().support
        ]);
      });
    case "css":
      return import(
        /* webpackChunkName: "code_lang_css" */ "@codemirror/lang-css"
      ).then(l => l[dataLang]());
    case "python":
      return import(
        /* webpackChunkName: "code_lang_python" */ "@codemirror/lang-python"
      ).then(l => l[dataLang]());
    case "sql":
      return import(
        /* webpackChunkName: "code_lang_sql" */ "@codemirror/lang-sql"
      ).then(l => l[dataLang]());
    case "java":
      return import(
        /* webpackChunkName: "code_lang_java" */ "@codemirror/lang-java"
      ).then(l => l[dataLang]());
    case "xml":
      return import(
        /* webpackChunkName: "code_lang_xml" */ "@codemirror/lang-xml"
      ).then(l => l[dataLang]());
    case "cpp":
      return import(
        /* webpackChunkName: "code_lang_cpp" */ "@codemirror/lang-cpp"
      ).then(l => l[dataLang]());
    case "php":
      return import(
        /* webpackChunkName: "code_lang_php" */ "@codemirror/lang-php"
      ).then(l => l[dataLang]());
    case "csharp":
      return import(
        /* webpackChunkName: "code_lang_clike" */
        `@codemirror/legacy-modes/mode/clike`
      ).then(l => {
        return StreamLanguage.define(l.csharp);
      });
    case "c":
      return import(
        /* webpackChunkName: "code_lang_clike" */
        `@codemirror/legacy-modes/mode/clike`
      ).then(l => {
        return StreamLanguage.define(l.c);
      });

    // webpack 5's parsing of package exports is blocking dynamic template imports that used to work
    // wether the issue is on cm's package or webpack side, i can no longer fix it in one chunk
    case "ruby":
      return import(
        /* webpackChunkName: "code_lang_ruby" */
        `@codemirror/legacy-modes/mode/ruby`
      ).then(l => {
        return StreamLanguage.define(l.ruby);
      });
    default:
      return import(
        /* webpackChunkName: "code_lang_lua" */
        `@codemirror/legacy-modes/mode/lua`
      ).then(l => {
        return StreamLanguage.define(l.lua);
      });
  }
}
export const basicSetup = (() => [
  lineNumbers(),
  highlightActiveLineGutter(),
  highlightSpecialChars(),
  history(),
  foldGutter(),
  drawSelection(),
  dropCursor(),
  EditorState.allowMultipleSelections.of(true),
  indentOnInput(),
  bracketMatching(),
  closeBrackets(),
  rectangularSelection(),
  crosshairCursor(),
  highlightActiveLine(),
  highlightSelectionMatches(),
  keymap.of([
    indentWithTab,
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...searchKeymap,
    ...historyKeymap,
    ...foldKeymap,
    ...completionKeymap
  ])
])();

export const darkMode = ref(true);

export const useCodemirror = (
  lang: string,
  autocomplete: boolean,
  value = "",
  onBlur: (() => void) | null = null,
  _extensions = []
) => {
  const inputValue = ref(value);
  let editor: EditorView | null = null;
  let dataLanguageExt: LanguageSupport | StreamLanguage<any> | null = null;

  const themeConfig = new Compartment();
  const readOnlyConfig = new Compartment();

  const { readOnly } = storeToRefs(useStore());
  const startTheme = darkMode.value
    ? materialDark
    : syntaxHighlighting(defaultHighlightStyle, {
        fallback: true
      });
  const extensions = [
    ..._extensions,
    themeConfig.of([startTheme]),
    readOnlyConfig.of([EditorView.editable.of(!readOnly.value)]),
    EditorView.updateListener.of(v => {
      if (v.docChanged) {
        inputValue.value = editor!.state.doc.toString();
      }
      if (onBlur && v.focusChanged && !v.view.hasFocus) onBlur();
    }),
    basicSetup
  ];
  if (autocomplete) {
    extensions.push(autocompletion());
  }

  watch(readOnly, value => {
    if (editor) {
      editor.dispatch({
        effects: readOnlyConfig.reconfigure([EditorView.editable.of(!value)])
      });
    }
  });

  watch(darkMode, value => {
    if (!editor) return;
    editor.dispatch({
      effects: themeConfig.reconfigure([
        value
          ? materialDark
          : syntaxHighlighting(defaultHighlightStyle, {
              fallback: true
            })
      ])
    });
  });

  async function createEditor(element: HTMLElement) {
    return await getLangExt(lang).then(x => {
      dataLanguageExt = x;
      extensions.push(x);
      editor = new EditorView({
        doc: inputValue.value,
        extensions: extensions,
        parent: element
      });
      return editor;
    });
  }

  function setEditorContents(text: string) {
    if (editor) {
      editor.dispatch({
        changes: {
          from: 0,
          to: editor.state.doc.length,
          insert: text
        }
      });
    }
  }

  function getSubmissionData() {
    if (!inputValue.value) return { css: "", html: "" };
    const text = inputValue.value;
    const div = document.createElement("div");
    const tmp = document.createElement("pre");
    tmp.style.fontFamily =
      "'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', 'Droid Sans Mono', monospace";
    tmp.style.fontSize = "12px";
    div.appendChild(tmp);
    let pos = 0;
    if (!dataLanguageExt) {
      // I can either throw error or return plain text
      return {
        css: "",
        html: text
      };
    }
    const ln = (dataLanguageExt as LanguageSupport).language
      ? (dataLanguageExt as LanguageSupport).language
      : (dataLanguageExt as StreamLanguage<any>);
    const tree = ln.parser.parse(text);
    highlightTree(tree, defaultHighlightStyle, (from, to, classes) => {
      if (from > pos) {
        const el = document.createElement("span");
        el.textContent = text.slice(pos, from);
        tmp.appendChild(el);
      }
      const el = document.createElement("span");
      el.textContent = text.slice(from, to);
      // arbitrary ts < not wasting cpu on parse/forloop
      if (classes) (el.classList as any) = classes;
      tmp.appendChild(el);
      pos = to;
    });
    if (tree.length > pos) {
      const el = document.createElement("span");
      el.textContent = text.slice(pos, tree.length);
      tmp.appendChild(el);
    }

    const rv = {
      css: defaultHighlightStyle.module!.getRules(),
      html: div.innerHTML
    };
    div.remove();
    return rv;
  }

  return {
    themeConfig,
    readOnlyConfig,
    createEditor,
    setEditorContents,
    value: computed(() => inputValue.value),
    getSubmissionData,
    darkMode
  };
};
